/**
 * Object containing the filters for each SERP page.
 * See https://bcg-ksg.atlassian.net/wiki/spaces/ENR/pages/3242426409/Filtering
 */
const filters = {
  BCG_INTERNAL_FILTERS: {
    FILTER_BAR: {
      MATERIALTYPE: {
        MULTISELECT: true,
        TREE_STRUCTURE: true,
        FULL_TREE: false,
        name: 'Content Type',
        QUERY_PARAM: 'materialType',
        SORT_ORDER: 1,
        ONLY_ADVANCED_SEARCH_FILTER: false,
        SINEQUA_PARAMETER_NAME: 'Material Type',
        ADV_SEARCH_PARAM: 'doctype',
        COLUMNNAME: 'sourcetree6',
        SOURCENAME: 'sourcetree6'
      },
      BCGINTERNALREGIONOFFICE: {
        MULTISELECT: true,
        TREE_STRUCTURE: true,
        FULL_TREE: true,
        name: 'Region/Office',
        QUERY_PARAM: 'bcgInternalRegionOffice',
        SORT_ORDER: 2,
        ONLY_ADVANCED_SEARCH_FILTER: false,
        SINEQUA_PARAMETER_NAME: 'BCG Internal Region Office',
        ADV_SEARCH_PARAM: 'RegionOffice',
        SOURCENAME: 'sourcetree9',
      },
      BCGOFFICENAMES: {
        MULTISELECT: true,
        TREE_STRUCTURE: false,
        FULL_TREE: false,
        name: 'Office Names',
        QUERY_PARAM: 'officeName',
        SORT_ORDER: 5,
        ONLY_ADVANCED_SEARCH_FILTER: true,
        SINEQUA_PARAMETER_NAME: 'BCG Office Names',
        ADV_SEARCH_PARAM: 'officeName',
        SOURCENAME: 'sourcecsv10',
      },
      FUNCTIONALPATOPIC: {
        MULTISELECT: true,
        TREE_STRUCTURE: true,
        FULL_TREE: true,
        name: 'Function',
        QUERY_PARAM: 'functionalPA',
        SORT_ORDER: 3,
        ONLY_ADVANCED_SEARCH_FILTER: false,
        SINEQUA_PARAMETER_NAME: 'Functional PA/Topic',
        ADV_SEARCH_PARAM: 'functionalleaf',
        SOURCENAME: 'sourcetree3',
      },
      DOCUPDATEDDATERANGE: {
        MULTISELECT: false,
        TREE_STRUCTURE: false,
        FULL_TREE: false,
        name: 'Date Revised',
        QUERY_PARAM: 'dateRevised',
        STATIC_OPTIONS: true,
        field: 'dateRevised',
        SORT_ORDER: 4,
        ONLY_ADVANCED_SEARCH_FILTER: false,
        RELATED_PARAMS: ['startDateRevised', 'endDateRevised'],
        options: [
          { display: 'Past Five Years' },
          { display: 'Past Two Years' },
          { display: 'Past Year' },
          {
            display: 'Past 6 Months',
            name: 'Past 6 Month'
          },
          { display: 'Past 90 Days' },
          { display: 'Past 30 Days' }
        ],
        SINEQUA_PARAMETER_NAME: 'DOC Updated Date Range',
        showFieldName: true,
        SOURCENAME: 'sourcedatetime12',
      },
      ADVANCED_SEARCH: {
        name: 'Advanced Search',
        QUERY_PARAM: 'advSearch',
        TYPE: 'input',
        ONLY_ADVANCED_SEARCH_FILTER: true,
        DISPLAY_FUNCTION: (display) => (display || '').replace(/\((.*?)::(.*?)\)?$/, '$2')
      },
    },
  },
  CASE_FILTERS: {
    ADVANCED_SEARCH_FILTER_TEMPLATE: {
      MULTISELECT: false,
      TREE_STRUCTURE: false,
      FULL_TREE: false,
      name: '',
      QUERY_PARAM: 'advSearch',
      SORT_ORDER: 1,
      ONLY_ADVANCED_SEARCH_FILTER: true,
    },
    FILTER_BAR: {
      CASEINDUSTRYPATOPIC: {
        MULTISELECT: true,
        TREE_STRUCTURE: true,
        FULL_TREE: true,
        name: 'Industry',
        QUERY_PARAM: 'industryPA',
        SORT_ORDER: 1,
        SINEQUA_PARAMETER_NAME: 'Case Industry PA/Topic',
        ADV_SEARCH_PARAM: 'industryleaf',
        SOURCENAME: 'sourcetree1',
      },
      CASEFUNCTIONALPATOPIC: {
        MULTISELECT: true,
        TREE_STRUCTURE: true,
        FULL_TREE: true,
        name: 'Function',
        QUERY_PARAM: 'functionalPA',
        SORT_ORDER: 2,
        SINEQUA_PARAMETER_NAME: 'Case Functional PA/Topic',
        ADV_SEARCH_PARAM: 'functionalleaf',
        SOURCENAME: 'sourcetree3',
      },
      CASETYPE: {
        MULTISELECT: true,
        TREE_STRUCTURE: false,
        name: 'Case Type',
        QUERY_PARAM: 'caseType',
        SORT_ORDER: 3,
        SINEQUA_PARAMETER_NAME: 'Case Type',
        ADV_SEARCH_PARAM: 'C_Type',
        SOURCENAME: 'sourcecsv45',
      },
      DATE_OPENED: {
        MULTISELECT: false,
        TREE_STRUCTURE: false,
        FULL_TREE: false,
        name: 'Date Opened',
        QUERY_PARAM: 'dateOpened',
        STATIC_OPTIONS: true,
        field: 'dateOpened',
        RELATED_PARAMS: ['startDateOpened', 'endDateOpened'],
        options: [
          { display: 'Past Five Years' },
          { display: 'Past Two Years' },
          { display: 'Past Year' },
          { display: 'Past 90 Days' },
          { display: 'Past 30 Days' }
        ],
        showFieldName: true,
        SORT_ORDER: 4,
        SINEQUA_PARAMETER_NAME: 'Date Range',
        SOURCENAME: 'sourcedatetime23',
      },
      DATE_CLOSED: {
        MULTISELECT: false,
        TREE_STRUCTURE: false,
        name: 'Date Closed',
        QUERY_PARAM: 'dateClosed',
        STATIC_OPTIONS: true,
        field: 'dateClosed',
        RELATED_PARAMS: ['startDateClosed', 'endDateClosed'],
        options: [
          { display: 'Past Five Years' },
          { display: 'Past Two Years' },
          { display: 'Past Year' },
          { display: 'Past 90 Days' },
          { display: 'Past 30 Days' }
        ],
        showFieldName: true,
        SORT_ORDER: 5,
        SINEQUA_PARAMETER_NAME: 'Closed Date Range',
        SOURCENAME: 'sourcedatetime24',
      },
      CLIENTNAME: {
        MULTISELECT: true,
        TREE_STRUCTURE: false,
        name: 'Client Name',
        QUERY_PARAM: 'clientName',
        SORT_ORDER: 6,
        ENTITLED: true,
        SORT_BY: 'name',
        SINEQUA_PARAMETER_NAME: 'Client Name',
        ONLY_ADVANCED_SEARCH_FILTER: true,
        ADV_SEARCH_PARAM: 'ncase|C_Client_Name',
        COLUMNNAME: 'sourcecsv44',
        SOURCENAME: 'sourcecsv44',
      },
      CLIENTBU: {
        MULTISELECT: true,
        TREE_STRUCTURE: false,
        name: 'Client BU',
        QUERY_PARAM: 'clientBu',
        SORT_ORDER: 7,
        ENTITLED: true,
        SORT_BY: 'name',
        SINEQUA_PARAMETER_NAME: 'Client Business Unit',
        ONLY_ADVANCED_SEARCH_FILTER: true,
        ADV_SEARCH_PARAM: 'C_ClientBU',
        SOURCENAME: 'sourcecsv48',
      },
      CASETEAMMEMBERWITHHRID: {
        MULTISELECT: true,
        TREE_STRUCTURE: false,
        name: 'Case Team',
        QUERY_PARAM: 'caseTeamMemberHrId',
        UPDATE_TEXT: true,
        SORT_ORDER: 8,
        DISPLAY_FUNCTION: (display) => (display || '').replaceAll(/[0-9]*/g, '').trim(),
        SORT_BY: (a, b) => {
          const alast = a.display.replace(/\w+\s(.*)\s\(\d*\)/, '$1');
          const blast = b.display.replace(/\w+\s(.*)\s\(\d*\)/, '$1');
          return alast > blast ? 1 : -1;
        },
        SINEQUA_PARAMETER_NAME: 'Case Team Member With HRID',
        ADV_SEARCH_PARAM: 'C_All_Case_Team',
        SOURCENAME: 'sourcecsv57',
      },
      VIGNETTES_ONLY: {
        name: 'Cases with Vignettes Only',
        QUERY_PARAM: 'vignettes',
        CHECKBOX_ONLY_FILTER: true,
        SINEQUA_PARAMETER_NAME: 'Vignettes Only',
      },
      ADVANCED_SEARCH: {
        name: 'Advanced Search',
        QUERY_PARAM: 'advSearch',
        TYPE: 'input',
        ONLY_ADVANCED_SEARCH_FILTER: true,
      },
      KEYWORDS: {
        name: 'Keywords',
        QUERY_PARAM: 'query',
        TYPE: 'input',
        PLACEHOLDER: 'Enter Keywords',
        ONLY_ADVANCED_SEARCH_FILTER: true,
        ENTITLED: false,
        HIDE_PILL: true,
        FREE_TEXT: true,
        SINEQUA_PARAMETER_NAME: 'Keywords',
      },
      CLIENT_NAME: {
        name: 'Client Name',
        QUERY_PARAM: 'ncase|C_Client_Name',
        TYPE: 'input',
        PLACEHOLDER: 'Enter Client Name',
        ONLY_ADVANCED_SEARCH_FILTER: true,
        ENTITLED: true,
        SINEQUA_PARAMETER_NAME: 'Client Name',
        ADV_SEARCH_PARAM: 'ncase|C_Client_Name',
        SOURCENAME: 'sourcecsv44',
      },
      CLIENT_DESCRIPTION: {
        name: 'Client Description',
        QUERY_PARAM: 'ncase|C_Client_Desc',
        TYPE: 'input',
        PLACEHOLDER: 'Enter Client Description',
        ONLY_ADVANCED_SEARCH_FILTER: true,
        ENTITLED: true,
        FREE_TEXT: true,
        DISPLAY_FUNCTION: (display) => (display || '').replace(/\((.*?)::(.*?)\)?$/, '$2'),
        SINEQUA_PARAMETER_NAME: 'Client Description',
      },
      CASE_NUMBER: {
        name: 'Case Number',
        QUERY_PARAM: 'ncase|C_Number',
        TYPE: 'input',
        PLACEHOLDER: 'Enter Case Number',
        ONLY_ADVANCED_SEARCH_FILTER: true,
        ENTITLED: true,
        FREE_TEXT: true,
        DISPLAY_FUNCTION: (display) => (display || '').replace(/\((.*?)::(.*?)\)?$/, '$2'),
        SINEQUA_PARAMETER_NAME: 'Case Number',
      },
      CASE_DESCRIPTION: {
        name: 'Case Description',
        QUERY_PARAM: 'C_Full_Desc',
        TYPE: 'input',
        PLACEHOLDER: 'Enter Case Description',
        ONLY_ADVANCED_SEARCH_FILTER: true,
        ENTITLED: false,
        FREE_TEXT: true,
        DISPLAY_FUNCTION: (display) => (display || '').replace(/\((.*?)::(.*?)\)?$/, '$2'),
        SINEQUA_PARAMETER_NAME: 'Case Description',
      },
      CASE_NAME: {
        name: 'Case Name',
        QUERY_PARAM: 'C_Name',
        TYPE: 'input',
        PLACEHOLDER: 'Enter Case Name',
        ONLY_ADVANCED_SEARCH_FILTER: true,
        ENTITLED: false,
        SINEQUA_PARAMETER_NAME: 'Case Name',
      },
      SUBJECTPAS: {
        name: 'Subject Area',
        QUERY_PARAM: 'subjectPA',
        MULTISELECT: true,
        TREE_STRUCTURE: true,
        FULL_TREE: true,
        ONLY_ADVANCED_SEARCH_FILTER: true,
        ENTITLED: false,
        SINEQUA_PARAMETER_NAME: 'Subject PAs',
        ADV_SEARCH_PARAM: 'subject',
        SOURCENAME: 'sourcetree8',
      },
      BCGREGIONOFFICE: {
        name: 'Region/Country/Office',
        QUERY_PARAM: 'bcgRegionOffice',
        MULTISELECT: true,
        TREE_STRUCTURE: true,
        FULL_TREE: true,
        ONLY_ADVANCED_SEARCH_FILTER: true,
        ENTITLED: false,
        SINEQUA_PARAMETER_NAME: 'BCG Region Office',
        INFO_TOOLTIP: 'Filter by region, country, or office for which the material pertains',
        ADV_SEARCH_PARAM: 'RegionOffice',
        SOURCENAME: 'sourcetree9',
      },
      STARTDATEOPENED: {
        QUERY_PARAM: 'startDateOpened',
        name: 'START DATE OPENED',
        HIDE_PILL: true,
        SINEQUA_PARAMETER_NAME: 'Start Date Opened',
      },
      ENDDATEOPENED: {
        QUERY_PARAM: 'endDateOpened',
        name: 'END DATE OPENED',
        HIDE_PILL: true,
        SINEQUA_PARAMETER_NAME: 'End Date Opened',
      },
      OPENEDDATERANGE: {
        ONLY_ADVANCED_SEARCH_FILTER: true,
        name: 'Date Opened',
        QUERY_PARAM: 'openedDateRange',
        RELATED_PARAMS: ['startDateOpened', 'endDateOpened'],
        type: 'daterange',
        showFieldName: true,
        SINEQUA_PARAMETER_NAME: 'Opened Date Range',
        SOURCENAME: 'sourcedatetime23',
      },
      STARTDATECLOSED: {
        QUERY_PARAM: 'startDateClosed',
        name: 'START DATE CLOSED',
        HIDE_PILL: true,
        SINEQUA_PARAMETER_NAME: 'Start Date Closed',
      },
      ENDDATECLOSED: {
        QUERY_PARAM: 'endDateClosed',
        name: 'END DATE CLOSED',
        HIDE_PILL: true,
        SINEQUA_PARAMETER_NAME: 'End Date Closed',
      },
      CLOSEDDATERANGE: {
        ONLY_ADVANCED_SEARCH_FILTER: true,
        name: 'Date Closed',
        QUERY_PARAM: 'closedDateRange',
        RELATED_PARAMS: ['startDateClosed', 'endDateClosed'],
        type: 'daterange',
        showFieldName: true,
        SINEQUA_PARAMETER_NAME: 'Closed Date Range',
        SOURCENAME: 'sourcedatetime24',
      },
    },
    VIEW_CONTROLS: {
      VIGNETTES: {
        NAME: 'Case Vignettes Status',
        QUERY_PARAM: 'vignettes',
        SINEQUA_PARAMETER_NAME: 'Case Vignettes Status',
        SOURCENAME: 'sourcecsv47',
      }
    },
  },
  KNOWLEDGE_FILTERS: {
    ADVANCED_SEARCH_FILTER_TEMPLATE: {
      MULTISELECT: false,
      TREE_STRUCTURE: false,
      FULL_TREE: false,
      name: '',
      QUERY_PARAM: 'advSearch',
      SORT_ORDER: 1,
      ONLY_ADVANCED_SEARCH_FILTER: true,
      HIDE_PILL: true
    },
    FILTER_BAR: {
      MATERIALTYPE: {
        MULTISELECT: true,
        TREE_STRUCTURE: true,
        FULL_TREE: false,
        name: 'Material Type',
        QUERY_PARAM: 'materialType',
        SORT_ORDER: 1,
        ONLY_ADVANCED_SEARCH_FILTER: false,
        SINEQUA_PARAMETER_NAME: 'Material Type',
        ADV_SEARCH_PARAM: 'doctype',
        COLUMNNAME: 'sourcetree6',
        SOURCENAME: 'sourcetree6'
      },
      INDUSTRYPATOPIC: {
        MULTISELECT: true,
        TREE_STRUCTURE: true,
        FULL_TREE: true,
        name: 'Industry',
        QUERY_PARAM: 'industryPA',
        SORT_ORDER: 2,
        ONLY_ADVANCED_SEARCH_FILTER: false,
        SINEQUA_PARAMETER_NAME: 'Industry PA/Topic',
        ADV_SEARCH_PARAM: 'industryleaf',
        SOURCENAME: 'sourcetree1',
        AND_OR_TOGGLEABLE: true
      },
      FUNCTIONALPATOPIC: {
        MULTISELECT: true,
        TREE_STRUCTURE: true,
        FULL_TREE: true,
        name: 'Function',
        QUERY_PARAM: 'functionalPA',
        SORT_ORDER: 3,
        ONLY_ADVANCED_SEARCH_FILTER: false,
        SINEQUA_PARAMETER_NAME: 'Functional PA/Topic',
        ADV_SEARCH_PARAM: 'functionalleaf',
        SOURCENAME: 'sourcetree3',
        AND_OR_TOGGLEABLE: true
      },
      SUBJECTPAS: {
        MULTISELECT: true,
        TREE_STRUCTURE: true,
        FULL_TREE: true,
        name: 'Subject Area',
        QUERY_PARAM: 'subjectPA',
        SORT_ORDER: 4,
        ONLY_ADVANCED_SEARCH_FILTER: false,
        SINEQUA_PARAMETER_NAME: 'Subject PAs',
        ADV_SEARCH_PARAM: 'subjectleaf',
        SOURCENAME: 'sourcetree8',
        AND_OR_TOGGLEABLE: true
      },
      DOCUPDATEDDATERANGE: {
        MULTISELECT: false,
        TREE_STRUCTURE: false,
        FULL_TREE: false,
        name: 'Date Revised',
        QUERY_PARAM: 'dateRevised',
        STATIC_OPTIONS: true,
        field: 'dateRevised',
        RELATED_PARAMS: ['startDateRevised', 'endDateRevised'],
        SORT_ORDER: 5,
        ONLY_ADVANCED_SEARCH_FILTER: false,
        options: [
          { display: 'Past Five Years' },
          { display: 'Past Two Years' },
          { display: 'Past Year' },
          {
            display: 'Past 6 Months',
            name: 'Past 6 Month'
          },
          { display: 'Past 90 Days' },
          { display: 'Past 30 Days' }
        ],
        SINEQUA_PARAMETER_NAME: 'DOC Updated Date Range',
        showFieldName: true,
        SOURCENAME: 'sourcedatetime12',
        DATE_RANGE: false
      },
      DATEPUBLISHED: {
        MULTISELECT: false,
        TREE_STRUCTURE: false,
        FULL_TREE: false,
        name: 'Date Published', // originally 'DOC Original Published Date Range'
        QUERY_PARAM: 'datePublished',
        STATIC_OPTIONS: true,
        field: 'datePublished',
        SORT_ORDER: 6,
        ONLY_ADVANCED_SEARCH_FILTER: true,
        RELATED_PARAMS: ['startDatePublished', 'endDatePublished'],
        options: [
          { display: 'Past Five Years' },
          { display: 'Past Two Years' },
          { display: 'Past Year' },
          {
            display: 'Past 6 Months',
            name: 'Past 6 Month'
          },
          { display: 'Past 90 Days' },
          { display: 'Past 30 Days' }
        ],
        SINEQUA_PARAMETER_NAME: 'DOC Original Published Date Range',
        showFieldName: true,
        SOURCENAME: 'sourcedatetime11',
        DATE_RANGE: false
      },
      BCGAUTHORS: {
        MULTISELECT: true,
        TREE_STRUCTURE: false,
        FULL_TREE: false,
        name: 'Authors',
        QUERY_PARAM: 'author',
        SORT_ORDER: 7,
        ONLY_ADVANCED_SEARCH_FILTER: true,
        SINEQUA_PARAMETER_NAME: 'BCG Authors',
        ADV_SEARCH_PARAM: 'authorlist',
        COLUMNNAME: 'sourcecsv57',
        SOURCENAME: 'author',
        DISPLAY_FUNCTION: (display) => (display || '').replaceAll(/[0-9]*/g, '').trim(),
        VALUE_FUNCTION: (value) => (value || '').replaceAll(/[0-9]*/g, '').trim(),
      },
      PA_RECOMMENDED_ONLY: {
        name: 'PA Recommended Only',
        QUERY_PARAM: 'recommendedBy',
        CHECKBOX_ONLY_FILTER: true,
        SOURCENAME: 'sourcecsv14',
        QUERYVALUEFIELD: 'name',
      },
      FRAMEWORK_SLIDES: {
        name: 'FRAMEWORK SLIDES',
        QUERY_PARAM: 'frameworkTagging',
        CHECKBOX_ONLY_FILTER: true,
        SOURCENAME: 'sourcecsv14',
        SINEQUA_PARAMETER_NAME: 'Framework Tagging'
      },
      SLIDE_LEVEL_SEARCH: {
        name: 'Show Slide Level Results',
        QUERY_PARAM: 'showSlideLevelResults',
        CHECKBOX_ONLY_FILTER: true,
        SOURCENAME: 'sourceslidelevelresults',
        SINEQUA_PARAMETER_NAME: 'Slide Level'
      },
      PA_RECOMMENDED: {
        MULTISELECT: false,
        FULL_TREE: false,
        name: 'PA Recommended',
        QUERY_PARAM: 'recommendedBy',
        field: 'recommendedBy',
        STATIC_OPTIONS: true,
        ONLY_ADVANCED_SEARCH_FILTER: false,
        CHECKBOX_ONLY_FILTER: true,
        CHECKBOX_FILTER_VALUE: 'PA/Topic', // used in conjunction with CHECKBOX_ONLY_FILTER
        INCLUDE_IN_BLANK_SEARCH: true, // used in conjunction with CHECKBOX_ONLY_FILTER
        SORT_ORDER: 9,
        MAPPING: [
          {
            id: 'PA/Topic',
            name: 'Has Certifications'
          }
        ]
      },
      ADVANCED_SEARCH: {
        name: 'Advanced Search',
        QUERY_PARAM: 'advSearch',
        TYPE: 'input',
        ONLY_ADVANCED_SEARCH_FILTER: true,
        DISPLAY_FUNCTION: (display) => (display || '').replace(/\((.*?)::(.*?)\)?$/, '$2')
      },
      KEYWORDS: {
        name: 'Keywords',
        QUERY_PARAM: 'query',
        TYPE: 'input',
        PLACEHOLDER: 'Enter Keywords',
        ONLY_ADVANCED_SEARCH_FILTER: true,
        SORT_ORDER: 8,
        HIDE_PILL: true,
        FREE_TEXT: true,
      },
      TITLE: {
        name: 'Title',
        QUERY_PARAM: 'title',
        TYPE: 'input',
        PLACEHOLDER: 'Enter Title',
        ONLY_ADVANCED_SEARCH_FILTER: true,
        FREE_TEXT: true,
        SINEQUA_PARAMETER_NAME: 'title',
        DISPLAY_FUNCTION: (display) => (display || '').replace(/\((.*?)::(.*?)\)?$/, '$2')
      },
      DESCRIPTION: {
        name: 'Description',
        QUERY_PARAM: 'materialdescription',
        TYPE: 'input',
        PLACEHOLDER: 'Enter Description',
        ONLY_ADVANCED_SEARCH_FILTER: true,
        FREE_TEXT: true,
        DISPLAY_FUNCTION: (display) => (display || '').replace(/\((.*?)::(.*?)\)?$/, '$2')
      },
      DOC_CONTENTS: {
        name: 'Document Contents',
        QUERY_PARAM: 'text',
        TYPE: 'input',
        PLACEHOLDER: 'Enter Document Contents',
        ONLY_ADVANCED_SEARCH_FILTER: true,
        FREE_TEXT: true,
        DISPLAY_FUNCTION: (display) => (display || '').replace(/\((.*?)::(.*?)\)?$/, '$2')
      },
      FIND_CONTENT_WITH: { // a wrapper for the following _LEFT fields
        IS_MODAL_ONLY: true,
        name: 'Find Content Within',
        QUERY_PARAM: 'advSearch'
      },
      TITLE_LEFT: {
        id: 'title_left',
        name: 'Title',
        QUERY_PARAM: 'title',
        TYPE: 'input',
        PLACEHOLDER: 'Type keywords to search within Title',
        ONLY_ADVANCED_SEARCH_FILTER: true,
        FREE_TEXT: true,
        SINEQUA_PARAMETER_NAME: 'title',
        DISPLAY_FUNCTION: (display) => (display || '').replace(/\((.*?)::(.*?)\)?$/, '$2')
      },
      DESCRIPTION_LEFT: {
        id: 'description_left',
        name: 'Description',
        QUERY_PARAM: 'materialdescription',
        TYPE: 'input',
        PLACEHOLDER: 'Type keywords to search within Description',
        ONLY_ADVANCED_SEARCH_FILTER: true,
        FREE_TEXT: true,
        DISPLAY_FUNCTION: (display) => (display || '').replace(/\((.*?)::(.*?)\)?$/, '$2')
      },
      SLIDES: {
        id: 'slides_left',
        name: 'Slides',
        QUERY_PARAM: 'text',
        TYPE: 'input',
        PLACEHOLDER: 'Type keywords to search within Slides',
        ONLY_ADVANCED_SEARCH_FILTER: true,
        FREE_TEXT: true,
        DISPLAY_FUNCTION: (display) => (display || '').replace(/\((.*?)::(.*?)\)?$/, '$2')
      },
      CASETEAMMEMBERWITHHRID: {
        MULTISELECT: true,
        TREE_STRUCTURE: false,
        FULL_TREE: false,
        name: 'Case Team',
        QUERY_PARAM: 'caseTeamMemberHrId',
        SORT_ORDER: 9,
        ONLY_ADVANCED_SEARCH_FILTER: true,
        SINEQUA_PARAMETER_NAME: 'BCG Authors With HRID',
        COLUMNNAME: 'sourcecsv57',
        SOURCENAME: 'sourcecsv57',
        DISPLAY_FUNCTION: (display) => (display || '').replaceAll(/[0-9]*/g, '').trim()
      },
      BCG_HRID: {
        MULTISELECT: true,
        TREE_STRUCTURE: false,
        FULL_TREE: false,
        name: 'BCG HRID',
        QUERY_PARAM: 'hrId',
        SORT_ORDER: 10,
        ONLY_ADVANCED_SEARCH_FILTER: true,
        SINEQUA_PARAMETER_NAME: 'BCG HRID',
        COLUMNNAME: 'sourcecsv58',
        SOURCENAME: 'sourcecsv58',
        ADV_SEARCH_PARAM: 'hrid',
      },
      BCGREGIONOFFICE: {
        MULTISELECT: true,
        TREE_STRUCTURE: true,
        FULL_TREE: true,
        name: 'Region/Office',
        QUERY_PARAM: 'bcgRegionOffice',
        ONLY_ADVANCED_SEARCH_FILTER: true,
        SHOW_TOOL_TIP: true,
        SORT_ORDER: 11,
        INFO_TOOLTIP: 'Filter by region, country, or office for which the material pertains',
        SINEQUA_PARAMETER_NAME: 'BCG Region Office',
        ADV_SEARCH_PARAM: 'regionoffice',
        COLUMNNAME: 'sourcetree9',
        SOURCENAME: 'sourcetree9',
      },
      LANGUAGES: {
        MULTISELECT: true,
        TREE_STRUCTURE: false,
        FULL_TREE: false,
        name: 'Language',
        QUERY_PARAM: 'language',
        ONLY_ADVANCED_SEARCH_FILTER: true,
        SHOW_TOOL_TIP: true,
        SORT_ORDER: 12,
        INFO_TOOLTIP: 'Filter by the language contained within the content of the material',
        SINEQUA_PARAMETER_NAME: 'Languages',
        ADV_SEARCH_PARAM: 'language',
        COLUMNNAME: 'sourcestr16',
        SOURCENAME: 'sourcestr16',
        QUERYVALUEFIELD: 'name'
      },
      FILE_FORMAT: {
        MULTISELECT: true,
        TREE_STRUCTURE: false,
        FULL_TREE: false,
        name: 'File Format',
        QUERY_PARAM: 'documentFormat',
        ONLY_ADVANCED_SEARCH_FILTER: true,
        SINEQUA_PARAMETER_NAME: 'Document Format',
        ADV_SEARCH_PARAM: 'fileformat',
        COLUMNNAME: 'sourcestr20',
        SOURCENAME: 'file_format'
      },
      DOCUMENTFORMAT: {
        MULTISELECT: true,
        TREE_STRUCTURE: false,
        FULL_TREE: false,
        name: 'Document Format',
        QUERY_PARAM: 'documentFormat',
        ONLY_ADVANCED_SEARCH_FILTER: true,
        SINEQUA_PARAMETER_NAME: 'Document Format',
        ADV_SEARCH_PARAM: 'fileformat',
        COLUMNNAME: 'sourcestr20',
        SOURCENAME: 'file_format'
      },
      STARTDATEPUBLISHED: {
        QUERY_PARAM: 'startDatePublished',
        HIDE_PILL: true,
        SINEQUA_PARAMETER_NAME: 'Start Date Published',
        ONLY_ADVANCED_SEARCH_FILTER: true,
      },
      ENDDATEPUBLISHED: {
        QUERY_PARAM: 'endDatePublished',
        HIDE_PILL: true,
        SINEQUA_PARAMETER_NAME: 'End Date Published',
        ONLY_ADVANCED_SEARCH_FILTER: true,
      },
      PUBLISHEDDATERANGE:{
        type: 'daterange',
        MULTISELECT: false,
        TREE_STRUCTURE: false,
        FULL_TREE: false,
        name: 'Date Published',
        QUERY_PARAM: 'publishedDateRange',
        STATIC_OPTIONS: false,
        field: 'datePublished',
        RELATED_PARAMS: ['startDatePublished', 'endDatePublished'],
        ONLY_ADVANCED_SEARCH_FILTER: true,
        SINEQUA_PARAMETER_NAME: 'Published Date Range',
        showFieldName: true,
        SOURCENAME: 'sourcedatetime11',
      },
      STARTDATEREVISED: {
        QUERY_PARAM: 'startDateRevised',
        HIDE_PILL: true,
        SINEQUA_PARAMETER_NAME: 'Start Date Revised',
        ONLY_ADVANCED_SEARCH_FILTER: true,
      },
      ENDDATEREVISED: {
        QUERY_PARAM: 'endDateRevised',
        HIDE_PILL: true,
        SINEQUA_PARAMETER_NAME: 'End Date Revised',
        ONLY_ADVANCED_SEARCH_FILTER: true,
      },
      REVISEDDATERANGE: {
        type: 'daterange',
        MULTISELECT: false,
        TREE_STRUCTURE: false,
        FULL_TREE: false,
        name: 'Date Revised',
        QUERY_PARAM: 'revisedDateRange',
        STATIC_OPTIONS: false,
        field: 'dateRevised',
        RELATED_PARAMS: ['startDateRevised', 'endDateRevised'],
        ONLY_ADVANCED_SEARCH_FILTER: true,
        SINEQUA_PARAMETER_NAME: 'Revised Date Range',
        showFieldName: true,
        SOURCENAME: 'sourcedatetime12'
      },
    },
    VIEW_CONTROLS: {
      PA_RECOMMENDED: {
        NAME: 'Recommended By',
        QUERY_PARAM: 'recommendedBy',
        SINEQUA_PARAMETER_NAME: 'Recommended By',
        SOURCENAME: 'sourcecsv14',
      }
    },
  },
  PA_TOPICS_FILTERS: {
    FILTER_BAR: {}
  },
  LAB_FILTERS: {
    FILTER_BAR: {
      INDUSTRYPATOPIC: {
        MULTISELECT: true,
        TREE_STRUCTURE: true,
        name: 'Industry',
        QUERY_PARAM: 'industryPA',
        SORT_ORDER: 2,
        SINEQUA_PARAMETER_NAME: 'Industry PA/Topic',
        ADV_SEARCH_PARAM: 'industryleaf',
        SOURCENAME: 'sourcetree1',
      },
      FUNCTIONALPATOPIC: {
        MULTISELECT: true,
        TREE_STRUCTURE: true,
        name: 'Function',
        QUERY_PARAM: 'functionalPA',
        SORT_ORDER: 3,
        SINEQUA_PARAMETER_NAME: 'Functional PA/Topic',
        ADV_SEARCH_PARAM: 'functionalleaf',
        SOURCENAME: 'sourcetree3',
      },
      DATERANGE: {
        MULTISELECT: false,
        TREE_STRUCTURE: false,
        name: 'Date Range',
        FULL_TREE: false,
        QUERY_PARAM: 'dateRange',
        STATIC_OPTIONS: true,
        field: 'dateRange',
        RELATED_PARAMS: ['startDateRange', 'endDateRange'],
        options: [
          { display: 'Past Five Years' },
          { display: 'Past Two Years' },
          { display: 'Past Year' },
          { display: 'Past 90 Days' },
          { display: 'Past 30 Days' }
        ],
        SORT_ORDER: 4,
        SINEQUA_PARAMETER_NAME: 'Date Range',
        showFieldName: true,
        SOURCENAME: 'sourcedatetime10',
      },
    },
    TRAININGANDTOOLS: {
      MULTISELECT: false,
      TREE_STRUCTURE: false,
      name: 'Training and Tools Topic',
      QUERY_PARAM: 'trainingAndTools',
      STATIC_OPTIONS: true,
      field: 'trainingAndTools',
      options: [
        { display: 'Learning at BCG' }
      ],
      SORT_ORDER: 5,
      SINEQUA_PARAMETER_NAME: 'Training and Tools Topic',
      SOURCENAME: 'sourcetree2',
    },
  },
  PEOPLE_FILTERS: {
    TITLE: {
      QUERY_PARAM: 'jobPosition',
      TREE_STRUCTURE: true,
      FULL_TREE: false,
      name: 'Positions',
      MULTISELECT: true,
      SORT_ORDER: 1
    },
    HOSTOFFICEID: {
      QUERY_PARAM: 'hostOfficeId',
      TREE_STRUCTURE: true,
      FULL_TREE: false,
      MAPPING_STATE: 'locations',
      name: 'Locations',
      MULTISELECT: true,
      SORT_ORDER: 2,
      filterOn: 'display'
    },
    HOSTOFFICEREGION: {
      QUERY_PARAM: 'hostOfficeRegion',
      TREE_STRUCTURE: true,
      FULL_TREE: false,
      name: 'Region',
      MULTISELECT: true,
      SORT_ORDER: 3
    },
    ALUMNI: {
      QUERY_PARAM: 'alumni',
      TREE_STRUCTURE: false,
      FULL_TREE: false,
      name: 'Alumni',
      field: 'alumni',
      MULTISELECT: false,
      STATIC_OPTIONS: true,
      SORT_ORDER: 4,
      MAPPING: [
        {
          id: 'YES',
          name: 'Alumni Only'
        },
        {
          id: 'NO',
          name: 'Exclude Alumni'
        }
      ]
    }
  },
  EXPERT_FILTERS: {
    ADVANCED_SEARCH_FILTER_TEMPLATE: {
      MULTISELECT: false,
      TREE_STRUCTURE: false,
      FULL_TREE: false,
      name: '',
      QUERY_PARAM: 'advSearch',
      SORT_ORDER: 1,
      ONLY_ADVANCED_SEARCH_FILTER: true,
      HIDE_PILL: true
    },
    FILTER_BAR: {
      ALUMNI: {
        QUERY_PARAM: 'includeAlumni',
        TREE_STRUCTURE: false,
        FULL_TREE: false,
        name: 'Include Alumni',
        field: 'includeAlumni',
        MULTISELECT: false,
        STATIC_OPTIONS: true,
        ONLY_ADVANCED_SEARCH_FILTER: false,
        CHECKBOX_ONLY_FILTER: true,
        INCLUDE_IN_BLANK_SEARCH: false, // used in conjunction with CHECKBOX_ONLY_FILTER
        SORT_ORDER: 6,
        PERSISTENT: false,
        MAPPING: [
          {
            id: 'YES',
            name: 'Include Alumni'
          },
          {
            id: 'NO',
            name: 'Exclude Alumni'
          }
        ]
      },
      CCO: {
        QUERY_PARAM: 'onlyCCOProfiles',
        TREE_STRUCTURE: false,
        FULL_TREE: false,
        name: 'CCO\'s Only',
        field: 'onlyCCOProfiles',
        MULTISELECT: false,
        STATIC_OPTIONS: true,
        ONLY_ADVANCED_SEARCH_FILTER: false,
        CHECKBOX_ONLY_FILTER: true,
        INCLUDE_IN_BLANK_SEARCH: false, // used in conjunction with CHECKBOX_ONLY_FILTER
        SORT_ORDER: 7,
        PERSISTENT: false,
        MAPPING: [
          {
            id: 'YES',
            name: 'CCO Only'
          },
          {
            id: 'NO',
            name: 'CCO Only'
          }
        ]
      },
      INDUSTRY: {
        MULTISELECT: true,
        FULL_TREE: true,
        name: 'Industry',
        QUERY_PARAM: 'industryPracticeArea',
        field: 'industryPracticeArea',
        SKIP_OPTIONS_SORT: true,
        SHOW_ON_TOP_FILTER: true,
        TOP_FILTER_SORT_ORDER: 21,
      },
      FUNCTION: {
        MULTISELECT: true,
        FULL_TREE: true,
        name: 'Function',
        QUERY_PARAM: 'functionalPracticeArea',
        field: 'functionalPracticeArea',
        SKIP_OPTIONS_SORT: true,
        SHOW_ON_TOP_FILTER: true,
        TOP_FILTER_SORT_ORDER: 23,
      },
      SUBJECTAREA: {
        MULTISELECT: true,
        FULL_TREE: true,
        name: 'Subject Area',
        topFilterName: 'Subject',
        QUERY_PARAM: 'subjectArea',
        field: 'subjectArea',
        SKIP_OPTIONS_SORT: true,
        SHOW_ON_TOP_FILTER: true,
        TOP_FILTER_SORT_ORDER: 25,
      },
      PAAFFILIATION: {
        MULTISELECT: true,
        FULL_TREE: false,
        name: 'PA Affiliation',
        QUERY_PARAM: 'paAffiliation',
        field: 'paAffiliation',
        SKIP_OPTIONS_SORT: true,
      },
      PALEADERSHIP: {
        MULTISELECT: true,
        FULL_TREE: false,
        name: 'PA Leadership',
        QUERY_PARAM: 'paLeadership',
        field: 'paLeadership',
        SKIP_OPTIONS_SORT: true,
      },
      EXPERTTYPE: {
        MULTISELECT: true,
        FULL_TREE: true,
        name: 'Career Track',
        QUERY_PARAM: 'expertType',
        field: 'expertType',
        SKIP_OPTIONS_SORT: true,
        SHOW_ON_TOP_FILTER: true,
        TOP_FILTER_SORT_ORDER: 10,
      },
      EXPERTISE: {
        MULTISELECT: true,
        TREE_STRUCTURE: true,
        FULL_TREE: true,
        name: 'Expertise',
        QUERY_PARAM: 'expertise',
        ONLY_ADVANCED_SEARCH_FILTER: false,
        field: 'expertise',
        SKIP_OPTIONS_SORT: true,
        SHOW_ON_TOP_FILTER: true,
        TOP_FILTER_SORT_ORDER: 20,
      },
      ORGANIZATION: {
        MULTISELECT: true,
        FULL_TREE: false,
        name: 'BCG Organization',
        topFilterName: 'Organization',
        QUERY_PARAM: 'organization',
        field: 'organization',
        SKIP_OPTIONS_SORT: true,
        SHOW_ON_TOP_FILTER: true,
        TOP_FILTER_SORT_ORDER: 40,
      },
      LOCATION: {
        MULTISELECT: true,
        FULL_TREE: true,
        name: 'Location',
        topFilterName: 'Locations',
        QUERY_PARAM: 'hostLocation',
        field: 'hostLocation',
        SKIP_OPTIONS_SORT: true,
        SHOW_ON_TOP_FILTER: true,
        TOP_FILTER_SORT_ORDER: 30,
      },
      LOCATIONS: {
        MULTISELECT: true,
        TREE_STRUCTURE: true,
        FULL_TREE: true,
        name: 'Locations',
        QUERY_PARAM: 'hostOffice',
        field: 'hostOffice',
        SKIP_OPTIONS_SORT: true,
        SHOW_ON_TOP_FILTER: true,
        TOP_FILTER_SORT_ORDER: 30,
      },
      INTERNALFUNCTION: {
        MULTISELECT: true,
        FULL_TREE: false,
        name: 'Internal Function',
        topFilterName: 'Department',
        QUERY_PARAM: 'jobFamilyGroup',
        field: 'jobFamilyGroup',
        SKIP_OPTIONS_SORT: true,
        SHOW_ON_TOP_FILTER: true,
        TOP_FILTER_SORT_ORDER: 50,
      },
      CASEDATERANGE: {
        MULTISELECT: false,
        FULL_TREE: false,
        name: 'Case Date Range',
        QUERY_PARAM: 'caseDate',
        field: 'caseDate',
        SKIP_OPTIONS_SORT: true,
        DATE_RANGE: true
      },
      ORGANIZATION2: {
        MULTISELECT: true,
        FULL_TREE: false,
        name: 'Organization',
        QUERY_PARAM: 'workCompany',
        field: 'workCompany',
        SKIP_OPTIONS_SORT: true,
      },
      SKILLS: {
        MULTISELECT: true,
        FULL_TREE: true,
        name: 'Skills',
        QUERY_PARAM: 'skills',
        field: 'skills',
        SKIP_OPTIONS_SORT: true,
        SHOW_ON_TOP_FILTER: true,
        TOP_FILTER_SORT_ORDER: 27,
      },
      TOOLS: {
        MULTISELECT: true,
        FULL_TREE: true,
        name: 'Tools and Solutions',
        topFilterName: 'Tools',
        QUERY_PARAM: 'toolsAndSolutions',
        field: 'toolsAndSolutions',
        SKIP_OPTIONS_SORT: true,
        SHOW_ON_TOP_FILTER: true,
        TOP_FILTER_SORT_ORDER: 29,
      },
      LANGUAGE: {
        MULTISELECT: true,
        FULL_TREE: false,
        name: 'Language',
        QUERY_PARAM: 'language',
        field: 'language',
        SKIP_OPTIONS_SORT: true,
      },
      EDUCATION: {
        MULTISELECT: true,
        FULL_TREE: false,
        name: 'Education',
        QUERY_PARAM: 'university',
        field: 'university',
        SKIP_OPTIONS_SORT: true,
      },
      HAS_CERTIFICATION: {
        MULTISELECT: false,
        FULL_TREE: false,
        name: 'Has Certifications',
        QUERY_PARAM: 'onlyCertifiedProfiles',
        field: 'onlyCertifiedProfiles',
        STATIC_OPTIONS: true,
        ONLY_ADVANCED_SEARCH_FILTER: false,
        CHECKBOX_ONLY_FILTER: true,
        INCLUDE_IN_BLANK_SEARCH: true, // used in conjunction with CHECKBOX_ONLY_FILTER
        SORT_ORDER: 8,
        MAPPING: [
          {
            id: 'YES',
            name: 'Has Certifications'
          },
          {
            id: 'NO',
            name: 'Has Certifications'
          }
        ]
      }
    },
    VIEW_CONTROLS: {
      PA_RECOMMENDED: {
        NAME: 'Recommended By',
        QUERY_PARAM: 'recommendedBy',
      }
    },
  },
};

export default filters;