import { addNewChatToChatHistory, groupChatHistoryRecordsByDate } from 'utils/chatHistory/chatHistory';
import axios from 'axios';
import CONFIG from 'config';
import { getRandomString } from 'utils/string-mapper/string-mapper';
import { ROLES, actions as chatActions } from 'redux/api/chat/chat';
import { handleStyledStatusMessages } from 'utils/misc/misc';

export const name = 'chatHistory';
export const UPDATE_GENAI_CUSTOMER_ID = 'UPDATE_GENAI_CUSTOMER_ID';
export const CHAT_HISTORY_PENDING = 'CHAT_HISTORY_PENDING';
export const CHAT_HISTORY_FAILURE = 'CHAT_HISTORY_FAILURE';
export const FETCH_CHAT_HISTORY = 'FETCH_CHAT_HISTORY';
export const SET_ACTIVE_CHAT_HISTORY = 'SET_ACTIVE_CHAT_HISTORY';
export const SET_CHAT_HISTORY_MESSAGE = 'SET_CHAT_HISTORY_MESSAGE';
export const ADD_NEW_CHAT_TO_CHAT_HISTORY = 'ADD_NEW_CHAT_TO_CHAT_HISTORY';
export const UPDATE_CHAT_HISTORY_TITLE = 'UPDATE_CHAT_HISTORY_TITLE';
export const DELETE_CHAT_HISTORY = 'DELETE_CHAT_HISTORY';

const initialState = {
  chatHistoryRecords: [],
  groupedChatHistory: {},
  activeChatIndex: 0,
  activeChatGroup: 'Today',
  chatHistoryLoading: false,
  chatHistoryByIdLoading: false,
  chatMessagesLoading: false,
  genaiCustmerId: null
};

const fetchGenAICustomerId = () => async (dispatch) => {
  // eslint-disable-next-line no-unused-vars
  const response = await axios.get(`${CONFIG.API_URL.GENAI_CUSTOMER_DATA}AGENTS`);
  const genaiCustmerId = 1; // set this to response?.customer_id; when api response is updated

  dispatch({ type: UPDATE_GENAI_CUSTOMER_ID, payload: { genaiCustmerId} });
};

const fetchChatHistory = (genaiCustmerId, pageNum = 0) => async (dispatch, getState) => {
  dispatch({ type: CHAT_HISTORY_PENDING, payload: { chatHistoryLoading: true } });
  const { chatHistory: { chatHistoryRecords, groupedChatHistory } } = getState();
  try {
    const response = await axios.get(CONFIG.API_URL.GENAI_CHAT_HISTORY(genaiCustmerId, 20, pageNum));
    if (response.status === 204) {
      dispatch({ type: CHAT_HISTORY_FAILURE, payload: response.status });
      return null;
    }
    if (pageNum === 0) {
      const newChatObj = addNewChatToChatHistory(response);
      if (newChatObj)
        response.unshift(newChatObj);
    }
    dispatch({
      type: FETCH_CHAT_HISTORY, payload: {
        chatHistoryRecords: [...chatHistoryRecords, ...response],
        groupedChatHistory: groupChatHistoryRecordsByDate(response, groupedChatHistory, pageNum)
      }
    });
  } catch (error) {
    console.error('KNCHAT callChatHistory: API call failed', error);
    dispatch({ type: CHAT_HISTORY_FAILURE, payload: error });
    return null;
  } finally {
    dispatch({ type: CHAT_HISTORY_PENDING, payload: { chatHistoryLoading: false } });
  }
};

const fetchChatHistoryById = (isStopped) => async (dispatch, getState) => {
  dispatch({ type: CHAT_HISTORY_PENDING, payload: { chatHistoryByIdLoading: true } });
  try {
    const { chat: { chatHistoryId }, chatHistory: { genaiCustmerId } } = getState();
    // Check if chatHistoryId is not exist then call API to get
    // latest chat history and update chatHistory state.
    if (!chatHistoryId) {
      try {
        const response = await axios.get(CONFIG.API_URL.GENAI_CHAT_HISTORY(genaiCustmerId, 5));
        const newChatId = response[0]?.chat_history_id;
        dispatch(chatActions.setChatHistoryId(newChatId));
        dispatch({
          type: FETCH_CHAT_HISTORY, payload: {
            chatHistoryRecords: response,
          }
        });
        return null;
      } catch (error) {
        console.error('KNCHAT callChatHistory: Fetch API call failed', error);
        dispatch({ type: CHAT_HISTORY_FAILURE, payload: error });
        return null;
      }
    }

    const { chatHistory: { activeChatIndex: index, activeChatGroup: group, groupedChatHistory, chatHistoryRecords } } = getState();
    const chatData = { ...groupedChatHistory };
    const currentRecord = chatData[group][index];

    if(currentRecord?.isNewChat === true && currentRecord?.title === 'New Chat' && isStopped) {
      chatData[group][index] = {
        ...currentRecord,
        title: `Chat ${chatHistoryId}`,
      };
      dispatch({
        type: FETCH_CHAT_HISTORY,
        payload: {
          groupedChatHistory: chatData,
          chatHistoryRecords
        }
      });
    }
    else if([`Chat ${chatHistoryId}`, 'New Chat'].includes(currentRecord?.title)) {
      const _response = await axios.get(`${CONFIG.API_URL.GENAI_CHAT_HISTORY_ID}${chatHistoryId}`);
      if (index !== -1) { 
        if (_response?.title !== null && currentRecord?.title !== _response.title) {
          chatData[group][index] = {
            ...currentRecord,
            title: _response.title,
          };
          dispatch({
            type: FETCH_CHAT_HISTORY,
            payload: {
              groupedChatHistory: chatData,
              chatHistoryRecords
            }
          });
        }
      }
    }
  } catch (error) {
    dispatch({ type: CHAT_HISTORY_FAILURE, payload: error });
    return null;
  } finally {
    dispatch({ type: CHAT_HISTORY_PENDING, payload: { chatHistoryByIdLoading: false } });
  }
};

const addNewChatObjectToChatHistory = (chatData) => async (dispatch) => {
  try {
    const newObj = addNewChatToChatHistory(chatData);
    if (newObj) {
      await dispatch({
        type: ADD_NEW_CHAT_TO_CHAT_HISTORY, payload: newObj
      });
    }
  } catch (error) {
    console.error('KNCHAT New Chat: Already Exist!');
    return null;
  }
};

const updateChatTitle = (title)=> async (dispatch, getState) => {
  const { chat: { chatHistoryId } } = getState();
  const { chatHistory: { activeChatIndex: index, activeChatGroup: group, groupedChatHistory, chatHistoryRecords } } = getState();
  const chatData = { ...groupedChatHistory };
  const currentRecord = chatData[group][index];
  if(currentRecord?.title !== title) {
    chatData[group][index] = {
      ...currentRecord,
      title: title ? title : `Chat ${chatHistoryId}`,
    };
    dispatch({
      type: FETCH_CHAT_HISTORY,
      payload: {
        groupedChatHistory: chatData,
        chatHistoryRecords
      }
    });
  }
};

const modifiedMessageArray = (messageArray) => {
  return messageArray.map(message => {
    if ([ROLES.STATUS, ROLES.USER_MESSAGE].includes(message.role)) {
      return {
        ...message,
        content: message?.toolGroup === CONFIG.AGENTS_TOOL_GROUPS.EXA_AI.TOOL 
          ? CONFIG.AGENTS_TOOL_GROUPS.EXA_AI.STATUS_MESSAGE 
          : handleStyledStatusMessages(message.content)
      };
    }
    if (message.role === ROLES.ASSISTANT && message?.toolGroup === CONFIG.AGENTS_TOOL_GROUPS.EXA_AI.TOOL) {
      return {
        ...message,
        content: convertMarkdownBoldToHtml(message.content)
      };
    }
    return message;
  }).filter(message => !!message.content);
};

const convertMarkdownBoldToHtml = (text) => {
  const boldRegex = /\*\*(.*?)\*\*/g;
  return text.replace(boldRegex, '<b>$1</b>');
};

const getChatHistoryMessagesByID = (chatHistoryID) => async (dispatch, getState) => {
  dispatch({ type: CHAT_HISTORY_PENDING, payload: { chatMessagesLoading: true } });
  if (!chatHistoryID) {
    return null;
  }
  try {
    const requestId = getState().chat.requestId || getRandomString(20);
    const requestData = {
      requestId: requestId,
      consumerId: 'KN',
    };

    const response = await axios.post(`${CONFIG.API_URL.GENAI_CHAT_HISTORY_ID}${chatHistoryID}`, requestData);
    dispatch(chatActions.startNewChat());
    const processedMessages = [];
    for (let index = 0; index < response.length; index++) {
      const message = {...response[index], toolGroup: response[index]?.toolgroup};
      delete message?.toolgroup;

      const processedMessage = await chatActions.processMessage(message, requestId);
      if (processedMessage !== null) {
        processedMessage.id = index;
        await dispatch(chatActions.getSourcesFromSearch(processedMessage, requestId));
        processedMessages.push(processedMessage);
      }
    }    
    const newmsgs = modifiedMessageArray(processedMessages); // for styling the status messages

    await dispatch(chatActions.updateChatMessages(newmsgs));
    dispatch({ type: CHAT_HISTORY_PENDING, payload: { chatMessagesLoading: false } });
  } catch (error) {
    console.error('KNCHAT callChatHistory: API call failed', error);
    dispatch({ type: CHAT_HISTORY_FAILURE, payload: error });
    return null;
  } finally {
    dispatch({ type: CHAT_HISTORY_PENDING, payload: { chatMessagesLoading: false } });
  }
};

const setActiveChatHistory = (index = 0, dateGroup = 'Today') => async (dispatch) => {
  dispatch({
    type: SET_ACTIVE_CHAT_HISTORY,
    payload: { index, dateGroup },
  });
};

const updateChatHistoryTitle = (item, newTitle) => async (dispatch, getState) => {
  const { chatHistory: { activeChatIndex: index, activeChatGroup: group, groupedChatHistory, genaiCustmerId } } = getState();
  const requestBody = {
    title: newTitle,
    customer_id: genaiCustmerId,
    chat_history_id: item.chat_history_id,
    request_id: getRandomString(20),
  };
  try {
    await axios.put(`${CONFIG.API_URL.GENAI_CHAT_HISTORY_TITLE}${item.chat_history_id}`, requestBody);
    const chatHistory = { ...groupedChatHistory };
    chatHistory[group][index].title = newTitle;
    dispatch({
      type: UPDATE_CHAT_HISTORY_TITLE,
      payload: { item, newTitle, chatHistory },
    });
  } catch (error) {
    console.error('KNCHAT editChatHistoryTitle: API call failed', error);
  }
};

const deleteChatHistory = (item) => async (dispatch, getState) => {
  const { chatHistory: { activeChatIndex: index, activeChatGroup: group, groupedChatHistory, genaiCustmerId } } = getState();
  const requestBody = {
    request_id: getRandomString(20),
    customer_id: genaiCustmerId
  };
  try {
    await axios.put(`${CONFIG.API_URL.GENAI_CHAT_HISTORY_ID}delete/${item.chat_history_id}`, requestBody);
    const chatHistory = { ...groupedChatHistory };
    chatHistory[group].splice(index, 1);
    dispatch({
      type: DELETE_CHAT_HISTORY,
      payload: { item, chatHistory },
    });
    dispatch(actions.setActiveChatHistory());
    dispatch(actions.addNewChatObjectToChatHistory(groupedChatHistory));
  } catch (error) {
    console.error('KNCHAT deleteChat: API call failed', error);
  }
};

const updateChatHistoryID = () => async (dispatch, getState) => {
  try {
    const { chat: { chatHistoryId } } = getState();
    const { chatHistory: { activeChatIndex: index, activeChatGroup: group, groupedChatHistory, chatHistoryRecords } } = getState();
    const chatData = { ...groupedChatHistory };
    const currentRecord = chatData[group][index];
    chatData[group][index] = {
      ...currentRecord,
      chat_history_id: chatHistoryId
    };
    dispatch({
      type: FETCH_CHAT_HISTORY,
      payload: {
        groupedChatHistory: chatData,
        chatHistoryRecords
      }
    });
  } catch (error) {
    dispatch({ type: CHAT_HISTORY_FAILURE, payload: error });
    return null;
  } finally {
    dispatch({ type: CHAT_HISTORY_PENDING, payload: { chatHistoryByIdLoading: false } });
  }
};

export const actions = {
  fetchChatHistory,
  setActiveChatHistory,
  addNewChatObjectToChatHistory,
  getChatHistoryMessagesByID,
  fetchChatHistoryById,
  updateChatHistoryTitle,
  deleteChatHistory,
  fetchGenAICustomerId,
  updateChatHistoryID,
  updateChatTitle
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_GENAI_CUSTOMER_ID:
      return {
        ...state,
        genaiCustmerId: action.payload?.genaiCustmerId
      };
    case CHAT_HISTORY_PENDING:
      return {
        ...state,
        error: false,
        errorMessage: '',
        chatHistoryLoading: action.payload?.chatHistoryLoading,
        chatHistoryByIdLoading: action.payload?.chatHistoryByIdLoading,
        chatMessagesLoading: action.payload?.chatMessagesLoading,
      };
    case FETCH_CHAT_HISTORY:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        chatHistoryRecords: action.payload?.chatHistoryRecords,
        groupedChatHistory: action.payload?.groupedChatHistory,
      };
    case CHAT_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload?.message,
        chatHistoryLoading: false,
      };
    case ADD_NEW_CHAT_TO_CHAT_HISTORY:
      const updatedGroupedChatHistory = { ...state.groupedChatHistory };
      updatedGroupedChatHistory['Today'] = [{ ...action.payload }, ...updatedGroupedChatHistory['Today']];
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        groupedChatHistory: updatedGroupedChatHistory
      };
    case SET_ACTIVE_CHAT_HISTORY:
      return {
        ...state,
        activeChatIndex: action.payload?.index,
        activeChatGroup: action.payload?.dateGroup,
      };
    case UPDATE_CHAT_HISTORY_TITLE:
      return {
        ...state,
        chatHistoryRecords: state.chatHistoryRecords.map((item) =>
          item === action.payload.item
            ? { ...item, title: action.payload.newTitle }
            : item
        ),
        groupedChatHistory: action.payload.chatHistory,
      };
    case DELETE_CHAT_HISTORY:
      return {
        ...state,
        chatHistoryRecords: state.chatHistoryRecords.filter(item => item !== action.payload.item),
        groupedChatHistory: action.payload.chatHistory,
      };
    default:
      return state;
  }
};
