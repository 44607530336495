import { merge } from 'lodash';
import ANALYTICS from 'utils/analytics/analytics';


export const chatStream = (chatId, threadID, time=0, chatHistoryId=null) => {
  _satellite.track('chatbot_send');
  const adobe = {
    chatID: chatId,
    threadID: threadID,
    officePage: {
      link: 'Enter Chat',
    },
    sessionID: chatHistoryId,
    page: { category: { primaryCategory: 'Navi' } }
  };
  if(time) {
    adobe.timeFirstToken = `${time}ms`;
  }
  ANALYTICS.page.trackEvent('Navigator_Button_Click', adobe);
};

export const sendMessageAnalytics = (enterChat) => {
  const adobe = {
    chatID: null,
    threadID: null,
    page: {
      pageInfo: {
        pageName: 'Navi',
      },
      category: {
        primaryCategory: 'Navi',
      },
    },
    officePage: { link: enterChat }
  };
  ANALYTICS.page.trackEvent('Navigator_Button_Click', adobe);
};

export const stopGeneration = (chatId, requestId) => {
  const adobe = {
    chatID: chatId,
    threadID: requestId,
    page: {
      pageInfo: {
        pageName: 'Navi',
      },
      category: {
        primaryCategory: 'Navi',
      },
    },
    officePage: {
      link: 'Stop Generation',
    },
  };
  ANALYTICS.page.trackEvent('Navigator_Button_Click', adobe);
};


export const submitChatFeedback = ({ chatId, requestId, sentiment }, userFeedback) => {
  const adobe = {
    chatID: chatId,
    threadID: requestId,
    resultExplicitFeedback: userFeedback,
    displayMode: 'chat',
    SERPview: 'chat view',
    selectedOptionReason: userFeedback,
    materialID: 'not available',
    resultType: 'not available',
    materialURL: 'not available',
    globalRelevance: 'not available',
    materialPAStatus: 'not available',
    attachmentID: 'not available',
    searchResultRanking: 'not available',
    resultRelevanceRating: sentiment,
    labCourseID: 'not available',
    labCourseName: 'not available',
    labLessonID: 'not available',
    labLessonName: 'not available',
    caseID: 'not available',
    KCPage: {
      id: 'not available',
    }
  };
  const appDefaults = ANALYTICS.page.getAppDefaults();
  const expertsDefaults = ANALYTICS.expertsDefaults.getDefaultQueryProps();
  const chatAdobe = merge({}, appDefaults, expertsDefaults, adobe);
  ANALYTICS.page.trackEvent('result_explicit_feedback', chatAdobe);
};

export const fetchChatHistoryClick = (chatId, requestId) => {
  const adobe = {
    chatID: chatId,
    threadID: requestId,
    resultExplicitFeedback: 'not available',
    displayMode: 'chat',
    SERPview: 'chat view',
    selectedOptionReason: 'not available',
    materialID: 'not available',
    resultType: 'not available',
    materialURL: 'not available',
    globalRelevance: 'not available',
    materialPAStatus: 'not available',
    attachmentID: 'not available',
    searchResultRanking: 'not available',
    resultRelevanceRating: 'positive',
    labCourseID: 'not available',
    labCourseName: 'not available',
    labLessonID: 'not available',
    labLessonName: 'not available',
    caseID: 'not available',
    KCPage: {
      id: 'not available',
    }
  };
  const appDefaults = ANALYTICS.page.getAppDefaults();
  const expertsDefaults = ANALYTICS.expertsDefaults.getDefaultQueryProps();
  const chatAdobe = merge({}, appDefaults, expertsDefaults, adobe);
  ANALYTICS.page.trackEvent('result_explicit_feedback', chatAdobe);
};

export const copyChatMsgBtnClick = (chatId, threadID) => {
  const adobe = {
    chatID: chatId,
    threadID: threadID,
    officePage: {
      link: 'copy',
    },
    page: { category: { primaryCategory: 'Navi' } }
  };

  ANALYTICS.page.trackEvent('Navigator_Button_Click', adobe);
};

export const navigationAnalytics = (type, chatId='N/A', requestId='N/A', chatHistoryId='N/A') => {
  const adobe = {
    officePage: {
      link: type,
    },
    page: {
      pageInfo: {
        pageName: 'Navi'
      },
      category: { 
        primaryCategory: 'Navi' 
      }
    },
    chatID: chatId,
    threadID: requestId,
    responseHistoryID: chatHistoryId
  };

  ANALYTICS.page.trackEvent('Navigator_Button_Click', adobe);
};

export const expandMoreAnalytics = (type, chatID, threadID) => {
  const adobe = {
    chatID,
    officePage: {
      link: type,
    },
    page: { 
      pageInfo: {pageName: 'Navi'},
      category: { primaryCategory: 'Navi' } 
    },
    threadID
  };

  ANALYTICS.page.trackEvent('Navigator_Button_Click', adobe);
};

export const naviErrorAnalytics = (error, chatId, threadID) => {
  const adobe = {
    chatID: chatId,
    threadID: threadID,
    page: {
      pageInfo: {
        pageName: 'Navi Error',
      },
      category: {
        primaryCategory: 'Navi',
      },
    },
    officePage: {
      link: error,
    },
  };

  ANALYTICS.page.trackEvent('Navigator_Button_Click', adobe);
};

export const promptTemplateAnalytics = () => {
  const adobe = {
    page: {
      category: {
        primaryCategory: 'Navi',
      }
    },
    officePage: {
      link: 'Prompt Templates',
    },
  };

  ANALYTICS.page.trackEvent('Navigator_Button_Click', adobe);
};